<template>
  <div class="container tasks pb-16 fadeInFast">
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-row class="pt-3 ps-8">
      <v-col cols="12" md="8" >
      <HeadlineDetailView
          v-if="task.status === 'Erledigt'"
          :icon="'mdi-check'"
          :headline="task.subject"
          :entity="'Aufgabe'"
      >
      </HeadlineDetailView>
      <HeadlineDetailView
          v-else
          :icon="'mdi-clock'"
          :headline="task.subject"
          :entity="'Aufgabe'"
      >
      </HeadlineDetailView>

      </v-col>
      <v-col cols="12" md="4" class="text-end">
        <v-btn
            v-if="task.status === 'Erledigt'"
            class="mb-5"
            @click="setTaskUndone(task.id)">
          nicht erledigt
        </v-btn>
        <v-btn
            v-else
            class="mb-5 primary"
            @click="setTaskDone(task.id)">
          <v-icon class="pe-3">mdi-check</v-icon>
          Erledigt
        </v-btn>
      </v-col>
    </v-row>

    <v-card flat class="detailCard rounded-xl pa-4">
      <Tab
          :tabs="['Allgemein']"
          :register="register"
          @changed="register=$event"
      >
      </Tab>
      <div class="row pa-4" v-if="register === 0">
        <div class="col-12 col-sm-7">
          <KeyValueBlock
              :title="''"
              :key-values="[
                    {
                      title: 'Betreff',
                      value: task.subject,
                      isEdit: false,
                      editBtn:{
                        icon:'mdi-check-circle',
                        entity: 'tasks',
                        id: task.id,
                        row: 'subject'
                      }
                    },
              {title: 'Status',
              value: task.status,
               editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'task',
                  id: task.id,
                  row: 'status',
                  type: 'autoComplete',
                  autoComplete:{
                    value: task.status,
                    items: ['Zu erledigen', 'In Arbeit', 'Erledigt']
                  }
              }},
              {title: 'Priorität', value: task.prio,
               editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'task',
                  id: task.id,
                  row: 'prio',
                  type: 'autoComplete',
                  autoComplete:{
                    value: task.prio,
                    items: ['Hoch', 'Mittel', 'Niedrig']
                  }
                }
              },
              {
                title: 'Fällig am',
                value: this.getStringDate(task.date),
                editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'task',
                  id: task.id,
                  row: 'date',
                  type: 'date',
                  oldValue: task.date
                }
              },
              {
                title: 'Hochzeit', value: task.weddingName,
                btn: {
                  icon:'mdi-ring',
                  entity: 'wedding',
                  id: task.weddingId
                },
                editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'task',
                  id: task.id,
                  row: 'weddingId',
                  type: 'autoComplete',
                  autoComplete:{
                    value: task.weddingId,
                    items: weddingsForSelection
                  }
              }
              },

              {title: 'Beschreibung', value: task.description,
              isEdit: false,
               editBtn:{
                icon:'mdi-check-circle',
                entity: 'tasks',
                id: task.id,
                row: 'description'
              }},

              ]"
              @editEntry="editEntry"
              @openEntry="openEntry($event)"
          >
          </KeyValueBlock>
          <CustomFieldBlock :entity="'task'" :user="user" :entryId="task.id"/>
        </div>
        <div class="col-12 col-sm-5">
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>

import {mapGetters} from "vuex"
import {date2String, error, msgObj} from "@/helper/helper";
const Message = () => import("@/components/generalUI/Message");
const KeyValueBlock = () => import("@/components/detailView/KeyValueBlock");
const HeadlineDetailView = () => import("@/components/detailView/HeadlineDetailView");
const CustomFieldBlock = () => import("@/components/detailView/CustomFieldBlock");
const Tab = () => import("@/components/generalUI/Tab");

export default {
  name: 'TaskDetail',
  components: {
    HeadlineDetailView,
    KeyValueBlock,
    Message,
    CustomFieldBlock,
    Tab
  },
  mounted() {
    this.$store.dispatch('task/getTask', {
      uid: this.user.id,
      taskId: this.$route.params.id
    }).catch((err) => {
      this.message = error(err)
    })

    this.$store.dispatch('wedding/getWeddingsForSelection', {
      uid: this.user.id,
    }).catch((err) => {
      this.message = error(err)
    })
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('task', {
      task: 'task',
    }),
    ...mapGetters('wedding', {
      weddingsForSelection: 'weddingsForSelection',
    }),
  },
  data() {
    return {
      dialog: false,
      register: 0,
      message: msgObj(),
    }
  },
  methods: {
    editEntry(payload) {
      this.$store.dispatch('customer/editField', {
        entity: "tasks",
        uid: this.user.id,
        id: payload.id,
        newValue: payload.newValue,
        row: payload.row
      }).then(() => {
        this.$store.dispatch('task/getTask', {taskId: payload.id, uid: this.user.id})
            .catch((err) => {
              this.message = error(err)
            })
      }).catch((err) => {
        this.message = error(err)
      })
    },
    openEntry(payload) {
      this.$router.push('/weddings/wedding/' + payload.id)
    },
    getStringDate(date) {
      return date2String(date)
    },
    setTaskDone(taskId) {
      this.$store.dispatch('task/setTaskDone', {
        uid: this.user.id,
        taskId
      }).then(() => {
        this.$router.push('/planner/todo')
      }).catch((err) => {
        this.message = error(err)
      })
    },
    setTaskUndone(taskId) {
      this.$store.dispatch('task/setTaskUndone', {
        uid: this.user.id,
        taskId
      }).then(() => {
        this.$router.push('/planner/todo')
      }).catch((err) => {
        this.message = error(err)
      })
    },
  }
}
</script>
