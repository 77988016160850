var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container tasks pb-16 fadeInFast"},[_c('Message',{attrs:{"message":_vm.message},on:{"closeAlert":function($event){_vm.message.show = false}}}),_c('v-row',{staticClass:"pt-3 ps-8"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[(_vm.task.status === 'Erledigt')?_c('HeadlineDetailView',{attrs:{"icon":'mdi-check',"headline":_vm.task.subject,"entity":'Aufgabe'}}):_c('HeadlineDetailView',{attrs:{"icon":'mdi-clock',"headline":_vm.task.subject,"entity":'Aufgabe'}})],1),_c('v-col',{staticClass:"text-end",attrs:{"cols":"12","md":"4"}},[(_vm.task.status === 'Erledigt')?_c('v-btn',{staticClass:"mb-5",on:{"click":function($event){return _vm.setTaskUndone(_vm.task.id)}}},[_vm._v(" nicht erledigt ")]):_c('v-btn',{staticClass:"mb-5 primary",on:{"click":function($event){return _vm.setTaskDone(_vm.task.id)}}},[_c('v-icon',{staticClass:"pe-3"},[_vm._v("mdi-check")]),_vm._v(" Erledigt ")],1)],1)],1),_c('v-card',{staticClass:"detailCard rounded-xl pa-4",attrs:{"flat":""}},[_c('Tab',{attrs:{"tabs":['Allgemein'],"register":_vm.register},on:{"changed":function($event){_vm.register=$event}}}),(_vm.register === 0)?_c('div',{staticClass:"row pa-4"},[_c('div',{staticClass:"col-12 col-sm-7"},[_c('KeyValueBlock',{attrs:{"title":'',"key-values":[
                  {
                    title: 'Betreff',
                    value: _vm.task.subject,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'tasks',
                      id: _vm.task.id,
                      row: 'subject'
                    }
                  },
            {title: 'Status',
            value: _vm.task.status,
             editBtn:{
                icon:'mdi-check-circle',
                entity: 'task',
                id: _vm.task.id,
                row: 'status',
                type: 'autoComplete',
                autoComplete:{
                  value: _vm.task.status,
                  items: ['Zu erledigen', 'In Arbeit', 'Erledigt']
                }
            }},
            {title: 'Priorität', value: _vm.task.prio,
             editBtn:{
                icon:'mdi-check-circle',
                entity: 'task',
                id: _vm.task.id,
                row: 'prio',
                type: 'autoComplete',
                autoComplete:{
                  value: _vm.task.prio,
                  items: ['Hoch', 'Mittel', 'Niedrig']
                }
              }
            },
            {
              title: 'Fällig am',
              value: this.getStringDate(_vm.task.date),
              editBtn:{
                icon:'mdi-check-circle',
                entity: 'task',
                id: _vm.task.id,
                row: 'date',
                type: 'date',
                oldValue: _vm.task.date
              }
            },
            {
              title: 'Hochzeit', value: _vm.task.weddingName,
              btn: {
                icon:'mdi-ring',
                entity: 'wedding',
                id: _vm.task.weddingId
              },
              editBtn:{
                icon:'mdi-check-circle',
                entity: 'task',
                id: _vm.task.id,
                row: 'weddingId',
                type: 'autoComplete',
                autoComplete:{
                  value: _vm.task.weddingId,
                  items: _vm.weddingsForSelection
                }
            }
            },

            {title: 'Beschreibung', value: _vm.task.description,
            isEdit: false,
             editBtn:{
              icon:'mdi-check-circle',
              entity: 'tasks',
              id: _vm.task.id,
              row: 'description'
            }} ]},on:{"editEntry":_vm.editEntry,"openEntry":function($event){return _vm.openEntry($event)}}}),_c('CustomFieldBlock',{attrs:{"entity":'task',"user":_vm.user,"entryId":_vm.task.id}})],1),_c('div',{staticClass:"col-12 col-sm-5"})]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }